// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-involved-js": () => import("/opt/build/repo/src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-history-nsl-js": () => import("/opt/build/repo/src/pages/history-nsl.js" /* webpackChunkName: "component---src-pages-history-nsl-js" */),
  "component---src-pages-history-warrant-canary-projects-js": () => import("/opt/build/repo/src/pages/history-warrant-canary-projects.js" /* webpackChunkName: "component---src-pages-history-warrant-canary-projects-js" */),
  "component---src-pages-history-warrant-canary-js": () => import("/opt/build/repo/src/pages/history-warrant-canary.js" /* webpackChunkName: "component---src-pages-history-warrant-canary-js" */),
  "component---src-pages-history-js": () => import("/opt/build/repo/src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-macarons-bake-js": () => import("/opt/build/repo/src/pages/macarons-bake.js" /* webpackChunkName: "component---src-pages-macarons-bake-js" */),
  "component---src-pages-macarons-js": () => import("/opt/build/repo/src/pages/macarons.js" /* webpackChunkName: "component---src-pages-macarons-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

